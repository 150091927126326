import React from 'react';
import cn from 'classnames';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import styles from 'styles/molecules/modals/PreviewImageModal.scss';
import ExternalLink from '../../icons/ExternalLink.js';

export interface PreviewImageModalProps {
    src: string;
    alt: string;
    srcSet?: string;
    onClose: () => void;
    open: boolean;
}

export const PreviewImageModal = ({
    src,
    alt,
    srcSet,
    open,
    onClose,
}: PreviewImageModalProps) => {
    const onExternalLinkClick = () => {
        window.open(src, '_blank');
    };

    const handleClose = (event: MouseEvent) => {
        event.stopPropagation();
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={cn([styles.dialog])}
            slotProps={{
                backdrop: {
                    className: styles.backdrop,
                },
            }}
        >
            <IconButton
                onClick={onExternalLinkClick}
                className={styles.external_link_button}
            >
                <ExternalLink className={styles.external_link} />
            </IconButton>
            <img src={src} alt={alt} srcSet={srcSet} className={styles.img} />
        </Dialog>
    );
};

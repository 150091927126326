import React from 'react';
import cn from 'classnames';
import Typography from '@mui/material/Typography';
import styles from 'styles/molecules/ValidatePasswordReqs.scss';
import CircleCheckmarkOutline from '../icons/CircleCheckmarkOutline.js';
import CircleX from '../icons/CircleX.js';

interface ValidatePasswordReqsProps {
    hasMinCharLength: boolean;
    hasUppercase: boolean;
    hasLowercase: boolean;
    hasNum: boolean;
    hasSpecialChar: boolean;
}

const ValidationReq = ({
    validateText,
    validated,
}: {
    validateText: string;
    validated: boolean;
}) => {
    return (
        <div
            className={cn([
                styles.validation_req,
                {
                    [styles.valid]: validated,
                },
            ])}
        >
            {validated ? (
                <CircleCheckmarkOutline fontSize="inherit" strokeWidth="thick" />
            ) : (
                <CircleX fontSize="inherit" />
            )}
            <Typography>{validateText}</Typography>
        </div>
    );
};

const ValidatePasswordReqs = ({
    hasMinCharLength,
    hasUppercase,
    hasLowercase,
    hasNum,
    hasSpecialChar,
}: ValidatePasswordReqsProps) => {
    return (
        <div className={styles.container}>
            <ValidationReq validateText="8 characters" validated={hasMinCharLength} />
            <ValidationReq validateText="Uppercase" validated={hasUppercase} />
            <ValidationReq validateText="Lowercase" validated={hasLowercase} />
            <ValidationReq validateText="Number" validated={hasNum} />
            <ValidationReq validateText="Symbol" validated={hasSpecialChar} />
        </div>
    );
};

export default ValidatePasswordReqs;

import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import styles from 'styles/molecules/TextInputGo.scss';
import TextInput, { TextInputProps } from '../atoms/TextInput.js';
import ArrowCircle from '../icons/ArrowCircle.js';

interface TextInputGoProps
    extends Omit<TextInputProps, 'startAdornment' | 'endAdornment' | 'onClick'>,
        Pick<IconButtonProps, 'onClick'> {}

const TextInputGo = (props: TextInputGoProps) => {
    const { onClick, disabled, ...otherProps } = props;

    return (
        <TextInput
            {...otherProps}
            variant="outlined"
            required
            className={styles.input}
            InputProps={{
                id: 'text-input-go-input',
                endAdornment: (
                    <IconButton
                        className={styles.button}
                        disabled={disabled}
                        onClick={onClick}
                    >
                        <ArrowCircle className={styles.icon} fontSize="large" />
                    </IconButton>
                ),
            }}
        />
    );
};

export default TextInputGo;

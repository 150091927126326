import React, { Fragment, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import styles from 'styles/molecules/modals/PreviewFileModal.scss';
import Chevron from '../../icons/Chevron.js';
import ExternalLink from '../../icons/ExternalLink.js';
import { getImageDimensions } from '../../_helpers/getImageDimensions.js';

export interface PreviewFileModalProps {
    fileList: FileList | {};
    initialSelectedFile: File;
    onClose: () => void;
    open: boolean;
}

export const PreviewFileModal = ({
    fileList,
    initialSelectedFile,
    open,
    onClose,
}: PreviewFileModalProps) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState('');
    const [fileDimensions, setFileDimensions] = useState({
        width: 0,
        height: 0,
        left: 0,
        top: 0,
    });
    const iframeRef = useRef(null);

    const calculateImageDimensions = () => {
        const img = new Image();

        img.onload = () => {
            const dimensions = getImageDimensions(
                img,
                window.innerWidth,
                window.innerHeight,
                0.6,
            );

            setFileDimensions(dimensions);
        };

        const src = URL.createObjectURL(selectedFile);
        img.src = src;
        setFileUrl(src);
    };

    useEffect(() => {
        if (initialSelectedFile) {
            setSelectedFile(initialSelectedFile);
        }
    }, [initialSelectedFile]);

    useEffect(() => {
        if (selectedFile) {
            calculateImageDimensions();

            window.addEventListener('resize', calculateImageDimensions);

            return () => {
                window.removeEventListener('resize', calculateImageDimensions);
            };
        }
    }, [selectedFile]);

    const fileKeys = Object.keys(fileList);

    if (!selectedFile) {
        return null;
    }

    const onChevronClick = (e) => {
        const { id } = e.currentTarget;

        const currentIndex = fileKeys.findIndex((key) => {
            return fileList[key] === selectedFile;
        });

        if (id === 'chevron-left') {
            const previousIndex = currentIndex - 1;

            if (previousIndex >= 0) {
                const previousFile = fileList[fileKeys[previousIndex]];

                setSelectedFile(previousFile);
                setFileUrl(URL.createObjectURL(previousFile));
            }
        } else {
            const nextIndex = currentIndex + 1;

            if (nextIndex < fileKeys.length) {
                const nextFile = fileList[fileKeys[nextIndex]];

                setSelectedFile(nextFile);
                setFileUrl(URL.createObjectURL(nextFile));
            }
        }
    };

    const onExternalLinkClick = () => {
        window.open(fileUrl, '_blank');
    };

    const isImage = selectedFile.type.startsWith('image/');

    const handleClose = (event: MouseEvent) => {
        event.stopPropagation();
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={cn([
                styles.dialog,
                {
                    [styles.hide_overflow]: !isImage,
                },
            ])}
            slotProps={{
                backdrop: {
                    className: styles.backdrop,
                },
            }}
        >
            {fileKeys.map((key, i) => {
                const hasMoreNext = i < fileKeys.length - 1;
                const hasMorePrevious = i > 0;

                if (fileList[key]?.name === selectedFile?.name) {
                    return (
                        <Fragment key={selectedFile.name}>
                            <IconButton
                                onClick={onExternalLinkClick}
                                className={styles.external_link_button}
                            >
                                <ExternalLink className={styles.external_link} />
                            </IconButton>
                            {hasMorePrevious && (
                                <IconButton
                                    id="chevron-left"
                                    className={cn([styles.button, styles.left])}
                                    onClick={onChevronClick}
                                >
                                    <Chevron className={styles.chevron} />
                                </IconButton>
                            )}
                            {hasMoreNext && (
                                <IconButton
                                    id="chevron-right"
                                    className={cn([styles.button, styles.right])}
                                    onClick={onChevronClick}
                                >
                                    <Chevron className={styles.chevron} />
                                </IconButton>
                            )}
                            {isImage ? (
                                <img
                                    src={fileUrl}
                                    style={fileDimensions}
                                    alt={selectedFile.name}
                                />
                            ) : (
                                <div className={styles.iframe_container}>
                                    <iframe
                                        ref={iframeRef}
                                        title={selectedFile.name}
                                        className={styles.iframe}
                                        aria-label={selectedFile.name}
                                        src={`${fileUrl}#view=fitH`}
                                        height="100%"
                                        width="100%"
                                    />
                                </div>
                            )}
                        </Fragment>
                    );
                }

                return null;
            })}
        </Dialog>
    );
};

import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from 'styles/molecules/FileAttachmentThumbnailV2.scss';
import Document from '../icons/svgs/Document.svg';
import Trash from '../icons/Trash.js';

export interface FileAttachmentThumbnailProps {
    file: File;
    className?: string;
    onDelete?: (file: File) => void;
    onClick?: (file: File) => void;
    isLoading?: boolean;
}

const FileAttachmentThumbnail = ({
    file,
    className,
    onDelete,
    onClick,
    isLoading,
}: FileAttachmentThumbnailProps) => {
    const [previewLink, setPreviewLink] = useState<string | null>(null);

    useEffect(() => {
        if (!file) return;

        // check if file is an image
        if (file?.type?.startsWith('image/')) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setPreviewLink(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    }, [file]);

    const removeAttachment = (e) => {
        e.stopPropagation();
        onDelete(file);
    };

    const handleClickFileAttachmentThumbnail = (e) => {
        e.stopPropagation();
        onClick(file);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.code === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            onClick(file);
        }
    };

    if (isLoading || !file) {
        return (
            <Skeleton
                component="div"
                variant="rectangular"
                className={cn([styles.skeleton, className])}
            />
        );
    }

    return (
        <div
            className={cn([styles.container, className])}
            onClick={handleClickFileAttachmentThumbnail}
            onKeyDown={handleKeyDown}
            aria-label="Preview file"
            role="button"
            tabIndex={0}
        >
            {previewLink ? (
                <img src={previewLink} alt={file.name} className={styles.img} />
            ) : (
                <div className={styles.document_container}>
                    <Document />
                </div>
            )}
            {onDelete && (
                <IconButton
                    className={styles.iconButton}
                    onClick={(e) => removeAttachment(e)}
                >
                    <Trash className={styles.trash} />
                </IconButton>
            )}
        </div>
    );
};

export default FileAttachmentThumbnail;

import React, { useRef } from 'react';
import cn from 'classnames';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from 'styles/molecules/ActivityCardRadio.scss';
import CircleCheckmarkOutline from '../icons/CircleCheckmarkOutline.js';
import { trackEvent } from '../../lib/analytics.js';
import { AnalyticsEvents } from '../../../types/AnalyticsEvents.js';
import { Activity } from '../../../types/Activity.js';
import { useCreateStripeCheckoutSession } from '../hooks/mutations/useCreateStripeCheckoutSession.js';
import { findDifferenceInDays } from '../_helpers/formatDateDistance.js';

export interface ActivityCardRadioProps {
    cardClassName?: string;
    activity: Activity;
    selectedActivity: Activity;
    halfCardIconSvg: React.FC;
    loading?: boolean;
    // isPremium?: boolean;
}

const ActivityCardRadio = ({
    cardClassName,
    activity,
    selectedActivity,
    halfCardIconSvg,
    loading,
}: // isPremium,
ActivityCardRadioProps) => {
    const cardRef = useRef(null);
    const createStripeCheckoutSession = useCreateStripeCheckoutSession();

    const HalfCardIconSvg = halfCardIconSvg;

    // const showPremiumOverlay =
    //     !activity.coming_soon && activity.type === 'cooperative' && !isPremium;

    // TODO: DELETE ONCE PRIVATE BETA IS OVER FOR QUICK WRITE
    // We are temporarily allowing all users to use quick write during the public beta
    const showPremiumOverlay = false;
    const isSelected = activity?.id === selectedActivity?.id && !showPremiumOverlay;

    const handleClickPremiumButton = () => {
        trackEvent(AnalyticsEvents.openCommentsTryPremiumButtonClicked);

        createStripeCheckoutSession.mutate({
            planType: 'annual',
        });
    };

    if (loading) {
        return (
            <Skeleton
                variant="rectangular"
                className={cn([styles.skeleton_card_base, cardClassName])}
            />
        );
    }

    const isQuickWrite = activity.slug === 'quick-write';
    const quickWriteBetaEndInDays = findDifferenceInDays(new Date('2025-04-16'));

    return (
        <div className={styles.container} ref={cardRef} id={activity.display_name}>
            {isQuickWrite && (
                <div
                    className={cn(['activity', styles.beta_badge])}
                >{`Public Beta for ${quickWriteBetaEndInDays} more days!`}</div>
            )}
            <FormControlLabel
                className={cn([styles.form_control_label])}
                label={
                    <Card
                        className={cn([
                            styles.skeleton_card_base,
                            cardClassName,
                            {
                                [styles.selected]: isSelected,
                            },
                        ])}
                    >
                        <div className={styles.premium_container}>
                            {showPremiumOverlay && (
                                <button
                                    type="button"
                                    aria-label="Upgrade to premium"
                                    className={cn({
                                        [styles.premium_overlay]: showPremiumOverlay,
                                    })}
                                    onClick={handleClickPremiumButton}
                                />
                            )}
                            <div
                                className={cn([
                                    styles.activity_card_icon_container,
                                    {
                                        [styles.all_in_bg]: activity.slug === 'all-in',
                                        [styles.pair_it_bg]: activity.slug === 'pair-it',
                                        [styles.battle_royale_bg]:
                                            activity.slug === 'battle-royale',
                                        [styles.quick_write_bg]:
                                            activity.slug === 'quick-write',
                                        [styles.partner_up_bg]:
                                            activity.slug === 'partner-up',
                                    },
                                ])}
                            >
                                <HalfCardIconSvg />
                            </div>
                            <h3 className={styles.activity_card_header}>
                                {activity.display_name}
                            </h3>
                            <p className={styles.activity_card_description}>
                                {activity.description}
                            </p>

                            <CircleCheckmarkOutline
                                className={cn([
                                    styles.checkmark,
                                    {
                                        [styles.checkmark_selected]: isSelected,
                                    },
                                ])}
                                strokeWidth="thick"
                            />

                            <div
                                className={cn([
                                    {
                                        [styles.coming_soon_overlay]:
                                            activity.coming_soon,
                                    },
                                ])}
                            />
                        </div>
                    </Card>
                }
                value={activity.id}
                control={
                    <Radio
                        className={styles.radio_input}
                        disabled={activity.coming_soon}
                        onClick={() =>
                            cardRef.current.scrollIntoView({ behavior: 'smooth' })
                        }
                    />
                }
            />
            {!activity.coming_soon && activity.type === 'cooperative' && (
                <div className={styles.ai_link}>
                    *{activity.display_name} uses AI -{' '}
                    <Link
                        href="https://myshortanswer.com/how-is-ai-used-in-partner-up/"
                        className={styles.resources_link}
                        target="_blank"
                        rel="noreferrer"
                        underline="hover"
                        onClick={() => trackEvent(AnalyticsEvents.resourcesLinkClicked)}
                    >
                        learn more
                    </Link>
                </div>
            )}
        </div>
    );
};

export default ActivityCardRadio;

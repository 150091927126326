import React from 'react';
import Link from '@mui/material/Link';
import styles from 'styles/molecules/UpgradePremiumModal.scss';
import Button from '../atoms/Button.js';
import { Dialog } from '../atoms/Dialog.js';

interface UpgradePremiumModalProps {
    isOpen: boolean;
    onSubmit: () => void;
    onClose: () => void;
}

const UpgradePremiumModal: React.FC<UpgradePremiumModalProps> = ({
    isOpen,
    onSubmit,
    onClose,
}) => {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <div className={styles.modal_contents}>
                <h2>
                    Learn more about <strong>Short Answer Premium</strong>
                </h2>

                <p>
                    Your free plan is limited to 3 total success criteria. Check our Short
                    Answer Premium plans and upgrade to add more.
                </p>

                <div className={styles.button_container}>
                    <Button kind="text" onClick={onClose} className={styles.text}>
                        Close
                    </Button>
                    <Button
                        type="submit"
                        kind="text"
                        onClick={onSubmit}
                        className={styles.link_secondary}
                    >
                        Upgrade
                    </Button>
                    <Button
                        component={Link}
                        className={styles.link_primary}
                        href="https://myshortanswer.com/see-plans/"
                        // @ts-ignore
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Short Answer premium plans"
                    >
                        See Plans
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default UpgradePremiumModal;

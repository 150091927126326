import React, { useEffect, Fragment } from 'react';
import cn from 'classnames';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import styles from 'styles/molecules/SubjectDropdown.scss';
import { subjectsList } from '../../../constants/classSubjects.js';
import { gradeLevels } from '../../../constants/gradeLevels.js';
import { SubjectsList } from '../../../types/Room.js';

export interface SubjectDropdownProps {
    gradeList: string[];
    subjects?: SubjectsList | [];
    setSubjects: React.Dispatch<React.SetStateAction<SubjectsList | []>>;
    hideHeading?: boolean;
    formControlClassName?: string;
    darkMode?: boolean;
    bold?: boolean;
    className?: string;
}

export const SubjectDropdown = ({
    gradeList,
    subjects,
    setSubjects,
    formControlClassName,
    darkMode,
    bold,
    className,
}: SubjectDropdownProps) => {
    const sortedGradeList = gradeList.sort((a, b) => {
        return gradeLevels.indexOf(a) - gradeLevels.indexOf(b);
    });

    const updateSubjects = (grade: string, value: string[]) => {
        setSubjects((prevState) => {
            const newSubjectsState = { ...prevState };
            newSubjectsState[grade] = [...value];

            return newSubjectsState;
        });
    };

    useEffect(() => {
        const existingGradesData = Object.keys(subjects);

        const gradesToRemove = existingGradesData.filter((grade) => {
            return !gradeList.includes(grade);
        });

        const update = { ...subjects };
        gradesToRemove.forEach((grade) => {
            delete update[grade];
        });
    }, [gradeList]);

    return (
        <div className={cn(styles.form_container, className)}>
            {sortedGradeList.map((grade: string, index: number) => (
                <Fragment key={`subject-dropdown-${grade}`}>
                    <InputLabel
                        htmlFor={`subject-by-grade-${index}`}
                        className={cn([
                            styles.heading,
                            {
                                [styles.dark_mode]: darkMode,
                                [styles.bold_subjects]: bold,
                            },
                        ])}
                    >
                        {`What ${grade} grade subjects do you teach?`}{' '}
                        <span>(add all that apply)</span>
                    </InputLabel>
                    <FormControl
                        id={`subject-by-grade-${index}`}
                        variant="filled"
                        className={cn(styles.form_control, formControlClassName)}
                    >
                        <Autocomplete
                            size="small"
                            multiple
                            id={grade}
                            className={styles.autocomplete}
                            options={subjectsList}
                            disableCloseOnSelect
                            value={subjects[grade] || []}
                            onChange={(e, value) => updateSubjects(grade, value)}
                            getOptionLabel={(option) => option}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox checked={selected} />
                                    {option}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </FormControl>
                </Fragment>
            ))}
        </div>
    );
};

import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from 'styles/molecules/Breadcrumbs.scss';
import BreadcrumbOverlay from '../atoms/BreadcrumbOverlay.js';

export interface BreadcrumbsProps {
    steps: React.ReactNode[];
    // Classname that wraps around the whole Breadcrumbs component
    className?: string;
    // Classname that wraps around the steps
    stepContainerClassName?: string;
    // Classname applied to each step
    stepClassName?: string;
    // A callback that will be called when the active element changes. id is the id of the active element
    onChange?: (id: string) => void;
    // The color for the currently active breadcrumb indicator
    activeIndicatorColor?: string;
    // Message to display in the BreadcrumbOverlay
    overlayMessage?: React.JSX.Element | string;
    // A boolean to determine if the overlay will show
    showOverlay?: boolean;
    // The scroll behavior for the scrollIntoView function
    scrollBlock?: 'start' | 'center' | 'end' | 'nearest';
}

export const Breadcrumbs = ({
    steps = [],
    className,
    stepContainerClassName,
    stepClassName,
    onChange,
    showOverlay,
    activeIndicatorColor,
    overlayMessage,
    scrollBlock,
}: BreadcrumbsProps) => {
    const elIds = steps.map((_, i) => `step-${i}`);
    const itemContainerElId = 'breadcrumb-steps-container';

    const [activeElId, setActiveElId] = useState(elIds?.[0] ?? '');
    const [isResizing, setIsResizing] = useState(false);
    const resizeTimeout = useRef(null);

    const scrollElIntoView = (id: string) => {
        const responseCard = document.getElementById(id);

        responseCard?.scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: scrollBlock ?? 'nearest',
        });
    };

    const variableColorStyle = {
        '--var-color': activeIndicatorColor,
    } as React.CSSProperties;

    const itemContainerEl = document.getElementById(itemContainerElId);

    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveElId(entry.target.id);
                    onChange?.(entry.target.id);
                }
            });
        },
        { threshold: 0.2, root: itemContainerEl },
    );

    const handleResize = () => {
        // Clear the timeout if it exists
        if (resizeTimeout.current) {
            clearTimeout(resizeTimeout.current);
        }

        // Start resize
        setIsResizing(true);

        // Set a timeout to end resize
        resizeTimeout.current = setTimeout(() => {
            setIsResizing(false);
            scrollElIntoView(elIds?.[0]);
        }, 500);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Cleanup after unmount
        return () => {
            window.removeEventListener('resize', handleResize);
            if (resizeTimeout.current) {
                clearTimeout(resizeTimeout.current);
            }
        };
    }, []);

    useEffect(() => {
        elIds.forEach((id) => {
            const element = document.getElementById(id);
            observer.observe(element);
        });

        // Cleanup after unmount
        return () => {
            observer?.disconnect();
        };
    }, [isResizing, elIds.length]);

    return (
        <>
            <div className={cn([styles.breadcrumb_container, className])}>
                {elIds?.map((id, i) => {
                    const itemNum = i + 1;

                    return (
                        <a
                            href={`#el-${itemNum}`}
                            key={id}
                            aria-label={`item-${itemNum}`}
                            style={variableColorStyle}
                            className={cn([
                                styles.a,
                                {
                                    [styles.active]: id === activeElId,
                                },
                            ])}
                            onClick={() => {
                                scrollElIntoView(id);
                            }}
                        />
                    );
                })}
            </div>
            <div
                id={itemContainerElId}
                className={cn(styles.step_container, stepContainerClassName)}
            >
                {steps.map((step, i) => {
                    const id = `step-${i}`;
                    return (
                        <div className={cn(styles.step, stepClassName)} key={id} id={id}>
                            {step}
                        </div>
                    );
                })}
            </div>
            <BreadcrumbOverlay
                className={styles.overlay}
                show={showOverlay}
                message={overlayMessage}
            />
        </>
    );
};

import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import styles from 'styles/molecules/TopicMenuList.scss';
import { captureException } from '@sentry/react';
import { useQueryClient } from 'react-query';
import Trash from '../icons/Trash.js';
import Send from '../icons/Send.js';
import Pencil from '../icons/Pencil.js';
import TextInput from '../atoms/TextInput.js';
import { QueryKeys } from '../../../constants/queryKeys.js';
import useDeleteTopic from '../hooks/mutations/useDeleteTopic.js';
import { useUpdateTopic } from '../hooks/mutations/useUpdateTopic.js';
import { Topic } from '../../../types/Topic.js';
import ActionPrompt from './modals/ActionPrompt.js';
import { SendTopicQuestionsModal } from './modals/SendTopicQuestionsModal.js';
import { Question } from '../../../types/Question.js';

export interface TopicMenuListProps {
    selectedTopic: Topic;
    topicQuestions: Question[];
    onDelete?: () => void;
}

const TopicMenuList = ({
    selectedTopic,
    topicQuestions,
    onDelete,
}: TopicMenuListProps) => {
    const [showDeleteTopicModal, setShowDeleteTopicModal] = useState(false);
    const [showRenameTopicModal, setShowRenameTopicModal] = useState(false);
    const [topicName, setTopicName] = useState('');
    const [showSendTopicQuestionsModal, setShowSendTopicQuestionsModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTopicName(selectedTopic?.name ?? '');
    }, [selectedTopic?.name]);

    const queryClient = useQueryClient();

    const updateTopic = useUpdateTopic();
    const deleteTopic = useDeleteTopic();

    const handleClickRename = () => {
        setShowRenameTopicModal(true);
    };

    const handleRenameTopic = async () => {
        try {
            if (topicName === '' || topicName === selectedTopic.name) {
                setShowRenameTopicModal(false);
                return;
            }

            setLoading(true);

            await updateTopic.mutateAsync({
                id: selectedTopic.id,
                name: topicName,
            });

            queryClient.invalidateQueries(QueryKeys.allRoomTopics);
            queryClient.invalidateQueries(QueryKeys.allRoomQuestions);
            setShowRenameTopicModal(false);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            captureException(err);
        }
    };

    const handleDeleteTopic = async () => {
        try {
            setLoading(true);
            await deleteTopic.mutateAsync({
                id: selectedTopic.id,
                roomId: selectedTopic.room_id,
            });

            onDelete?.();

            queryClient.invalidateQueries(QueryKeys.allRoomTopics);
            queryClient.invalidateQueries(QueryKeys.allRoomQuestions);
            setShowDeleteTopicModal(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            captureException(error);
        }
    };

    return (
        <>
            <MenuItem onClick={handleClickRename}>
                <ListItemIcon>
                    <Pencil className={styles.menu_item_icon} fontSize="small" />
                </ListItemIcon>
                <ListItemText className={styles.menu_item_text}>Rename</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setShowSendTopicQuestionsModal(true)}>
                <ListItemIcon>
                    <Send className={styles.menu_item_icon} fontSize="medium" />
                </ListItemIcon>
                <ListItemText className={styles.menu_item_text}>Share</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setShowDeleteTopicModal(true)}>
                <ListItemIcon>
                    <Trash className={styles.menu_item_icon} fontSize="small" />
                </ListItemIcon>
                <ListItemText className={styles.menu_item_text}>Delete</ListItemText>
            </MenuItem>
            <ActionPrompt
                open={showRenameTopicModal}
                title="Rename Topic"
                onClose={() => setShowRenameTopicModal(false)}
                content={
                    <TextInput
                        placeholder="Topic Name"
                        autoFocus
                        value={topicName}
                        className={styles.rename_topic_input}
                        onChange={(e) => setTopicName(e.target.value)}
                    />
                }
                primaryButtonLoading={loading}
                primaryButtonDisabled={
                    topicName === '' || topicName === selectedTopic.name
                }
                onPrimaryAction={handleRenameTopic}
                onSecondaryAction={() => setShowRenameTopicModal(false)}
            />
            <ActionPrompt
                open={showDeleteTopicModal}
                title={`Delete ${selectedTopic?.name}?`}
                onClose={() => setShowDeleteTopicModal(false)}
                content={
                    <div className={styles.delete_topic_content}>
                        Are you sure you want to delete this topic? All questions for this
                        topic will be moved to the &apos;Recents&apos; tab.
                    </div>
                }
                onPrimaryAction={handleDeleteTopic}
                onSecondaryAction={() => setShowDeleteTopicModal(false)}
            />
            <SendTopicQuestionsModal
                show={showSendTopicQuestionsModal}
                selectedTopic={selectedTopic}
                topicQuestions={topicQuestions}
                onClose={() => setShowSendTopicQuestionsModal(false)}
            />
        </>
    );
};

export default TopicMenuList;

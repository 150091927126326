import Collapse from '@mui/material/Collapse';
import cn from 'classnames';
import React, { useState } from 'react';
import styles from 'styles/molecules/AddResponseQuestionHeader.scss';
import { FeedbackValue } from '../../../types/Feedback.js';
import Chevron from '../icons/Chevron.js';
import StudentVerticalThreeDotMenu from '../templates/Footer/StudentVerticalThreeDotMenu.js';
import FileAttachmentThumbnail from './FileAttachmentThumbnailV2.js';
import { PreviewFileModal } from './modals/PreviewFileModal.js';
import { useSessionQuestionFileAttachments } from '../hooks/queries/useSessionQuestionFileAttachments.js';
import { Session } from '../../../types/Session.js';
import { FeedbackChip } from './FeedbackChipV2.js';

export interface AddResponseQuestionHeaderProps {
    questionText: string;
    criteria: FeedbackValue[];
    session: Session;
    className?: string;
}

const AddResponseQuestionHeader = ({
    questionText,
    criteria,
    className,
    session,
}: AddResponseQuestionHeaderProps) => {
    const [showFilePreview, setShowFilePreview] = useState<File | null>(null);
    const [showDetails, setShowDetails] = useState(true);

    const {
        attachedFiles,
        isLoading: isLoadingAttachedFiles,
        fileCount,
    } = useSessionQuestionFileAttachments({
        questionId: session?.question_id,
        attachments: session?.question_attachments,
    });

    let fileKeys = Object.keys(attachedFiles ?? {});

    if (!fileKeys?.length && isLoadingAttachedFiles) {
        fileKeys = Array.from(Array(fileCount)).map((_, i) => `attachment-${i}`);
    }

    return (
        <>
            <div className={cn([styles.container, className])}>
                <h3 className={styles.question_text}>{questionText}</h3>
                <div className={styles.threedot}>
                    <StudentVerticalThreeDotMenu />
                </div>
                <Collapse in={showDetails} className={styles.collapse}>
                    <div className={styles.collapse_content_container}>
                        <div className={styles.file_attachment_container}>
                            {fileKeys?.map((key) => {
                                const file = attachedFiles?.[key];

                                return (
                                    <FileAttachmentThumbnail
                                        key={key}
                                        file={file}
                                        className={styles.file_attachment}
                                        isLoading={isLoadingAttachedFiles}
                                        onClick={setShowFilePreview}
                                    />
                                );
                            })}
                        </div>
                        <div className={styles.criteria_container}>
                            {criteria.map((feedbackOpt) => {
                                return (
                                    <FeedbackChip
                                        checkmarkColor="#3437D1"
                                        className={styles.criteria}
                                        key={feedbackOpt.id}
                                        feedbackValue={feedbackOpt}
                                        strokeWidth="thin"
                                    />
                                );
                            })}
                        </div>
                    </div>
                </Collapse>
                <button
                    type="button"
                    className={styles.chevron_btn}
                    onClick={() => setShowDetails(!showDetails)}
                >
                    See {showDetails ? 'less' : 'more'}{' '}
                    <Chevron
                        className={
                            showDetails
                                ? styles.see_less_chevron
                                : styles.see_more_chevron
                        }
                    />
                </button>
            </div>
            <PreviewFileModal
                fileList={attachedFiles ?? {}}
                initialSelectedFile={showFilePreview}
                open={!!showFilePreview}
                onClose={() => setShowFilePreview(null)}
            />
        </>
    );
};

export default AddResponseQuestionHeader;

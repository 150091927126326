import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import styles from 'styles/molecules/modals/ConfirmationModal.scss';
import Button from '../../atoms/Button.js';
import { Dialog } from '../../atoms/Dialog.js';

export interface ConfirmationModalProps {
    /** Whether to show the modal */
    open: boolean;
    /** Callback function to call when closing dialog */
    onClose: () => void;
    /** Text to show in DialogTitle */
    title?: string;
    /** Image content to be placed above title */
    image?: React.ReactNode;
    /** Content to be slotted under the title */
    content?: React.ReactNode;
    /** Text to show on top confirm button, defaults to "Confirm" */
    confirmText?: string;
    /** Text to show on bottom cancel button, defaults to "Cancel" */
    cancelText?: string;
    /** Callback function to call when clicking confirm button */
    onConfirm?: () => void;
    /** Callback function to call when clicking cancel button, defaults to onClose */
    onCancel?: () => void;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
    const {
        open,
        onClose,
        title,
        image,
        content,
        confirmText = 'Confirm',
        cancelText = 'Cancel',
        onConfirm,
        onCancel = onClose,
    } = props;

    return (
        <Dialog open={open} onClose={onClose}>
            <span className={styles.container}>
                {image}

                {title && (
                    <DialogTitle className={styles.h1} variant="h1" color="black">
                        {title}
                    </DialogTitle>
                )}

                {content && <DialogContent>{content}</DialogContent>}

                <DialogActions>
                    <div className={styles.button_container}>
                        <Button fullWidth kind="primary" onClick={onConfirm}>
                            {confirmText}
                        </Button>
                        <Button
                            className={styles.text_button}
                            fullWidth
                            kind="text"
                            onClick={onCancel}
                        >
                            {cancelText}
                        </Button>
                    </div>
                </DialogActions>
            </span>
        </Dialog>
    );
};

export default ConfirmationModal;

import React from 'react';
import { captureException } from '@sentry/react';
import DialogContent from '@mui/material/DialogContent';
import styles from 'styles/molecules/modals/WelcomeModal.scss';
import { Dialog } from '../../atoms/Dialog.js';
import { useDismissWelcomeModal } from '../../hooks/mutations/useDismissWelcomeModal.js';
import LogoTextIcon from '../../icons/LogoTextIcon.js';

export const WelcomeModal = ({
    show,
    onClose,
}: {
    show: boolean;
    onClose: () => void;
}) => {
    const dismissModal = useDismissWelcomeModal();

    const handleClose = () => {
        onClose();

        dismissModal.mutate(null, {
            onError: (error) => {
                captureException(error);
            },
        });
    };

    return (
        <Dialog
            id="welcome-modal"
            open={show}
            onClose={handleClose}
            closeButtonClassName={styles.dialog_close_button}
        >
            <DialogContent className={styles.dialog_content}>
                <LogoTextIcon className={styles.logo} />

                <p className={styles.p}>
                    Welcome to Short Answer, watch the video below to get started! Join
                    our{' '}
                    <a
                        className={styles.a}
                        href="https://www.facebook.com/groups/593089402256533"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Short Answer Teacher Community
                    </a>{' '}
                    to get ideas for your classroom, provide feedback, suggest features,
                    and more!
                </p>
                <div className={styles.iframe_container}>
                    <iframe
                        className={styles.iframe}
                        src="https://www.youtube.com/embed/P8OY_gstPHQ?si=p8R-wFGVV6iNkg81?rel=0"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

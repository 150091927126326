import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import styles from 'styles/molecules/modals/MaxCriteriaExceededModal.scss';
import Button from '../../atoms/Button.js';
import { Dialog } from '../../atoms/Dialog.js';
import Pencil from '../../icons/Pencil.js';
import WarningOutline from '../../icons/WarningOutline.js';

export const MaxCriteriaExceededModal = ({
    show,
    onClose,
    onEdit,
}: {
    show: boolean;
    onClose: () => void;
    onEdit: () => void;
}) => {
    return (
        <Dialog open={show} onClose={onClose}>
            <DialogContent className={styles.dialog_content}>
                <div className={styles.content_container}>
                    <div className={styles.warning_container}>
                        <WarningOutline className={styles.warning} />
                    </div>
                    <h1 className={styles.title}>Edit to launch</h1>
                    <p className={styles.subtitle}>
                        Your question has more than 3 feedback criteria.{' '}
                        <a
                            className={styles.anchor_link}
                            target="_blank"
                            href="https://myshortanswer.com/pricing-page-monthly/"
                            rel="noreferrer"
                        >
                            Short Answer Basics
                        </a>{' '}
                        users are limited to 3 feedback criteria or less. Please edit your
                        question to launch.
                    </p>
                    <div className={styles.button_container}>
                        <Button kind="text" onClick={() => onClose()}>
                            Back
                        </Button>

                        <Button
                            kind="primary"
                            onClick={() => onEdit()}
                            className={styles.button}
                            startIcon={<Pencil />}
                        >
                            Edit
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

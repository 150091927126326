import React from 'react';
import cn from 'classnames';
import styles from 'styles/molecules/writingScaffolds/WritingScaffoldOptions.scss';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import { WritingScaffolds } from '../../../../types/WritingScaffolds.js';
import { Question } from '../../../../types/Question.js';
import { convertScaffoldKeyToPlainText } from '../../_helpers/convertScaffoldKeyToPlainText.js';
import scaffoldOptions from '../../lib/writingScaffoldOptions.js';

export interface WritingScaffoldOptionsProps {
    isPremium: boolean;
    selectedWritingScaffolds: WritingScaffolds;
    updateWritingScaffoldOptions: (e: React.ChangeEvent<HTMLInputElement>) => void;
    question: Question;
}

export const WritingScaffoldOptions = ({
    isPremium,
    selectedWritingScaffolds,
    updateWritingScaffoldOptions,
    question,
}: WritingScaffoldOptionsProps) => {
    return (
        <div className={styles.container} id="scaffold_container">
            {scaffoldOptions.map((scaffold) => {
                const Icon = scaffold.icon;

                // Check if the writing scaffold exists for the question
                const missingScaffold =
                    scaffold.name === 'translate'
                        ? false
                        : !question?.writing_scaffolds?.[scaffold.name];
                const isChecked = selectedWritingScaffolds?.[scaffold.name] === true;

                return (
                    <Tooltip
                        key={scaffold.name}
                        title={scaffold.disabledTooltipMessage}
                        disableHoverListener={!missingScaffold}
                        arrow
                        classes={{
                            tooltip: styles.tooltip,
                            arrow: styles.tooltip_arrow,
                        }}
                        placement="top"
                    >
                        <label htmlFor={scaffold.name} className={styles.scaffold_option}>
                            <div
                                className={cn([
                                    missingScaffold || !isPremium
                                        ? styles.disabled
                                        : undefined,
                                    styles.header_container,
                                ])}
                            >
                                <div className={styles.name_container}>
                                    <Icon
                                        className={cn([
                                            styles.icon,
                                            {
                                                [styles.burnt_orange_color]:
                                                    scaffold.name === 'sentence-stems',
                                                [styles.blue_purple_color]:
                                                    scaffold.name === 'outline',
                                                [styles.magenta_color]:
                                                    scaffold.name === 'translate',
                                            },
                                        ])}
                                    />
                                    <h4
                                        className={cn([
                                            styles.h4,
                                            {
                                                [styles.burnt_orange_color]:
                                                    scaffold.name === 'sentence-stems',
                                                [styles.blue_purple_color]:
                                                    scaffold.name === 'outline',
                                                [styles.magenta_color]:
                                                    scaffold.name === 'translate',
                                            },
                                        ])}
                                    >
                                        {convertScaffoldKeyToPlainText(
                                            scaffold.name as keyof WritingScaffolds,
                                        )}
                                    </h4>
                                </div>
                                <p className={styles.description}>
                                    {scaffold.description}
                                </p>
                            </div>
                            <RadioGroup
                                className={styles.format_radio_group}
                                value={
                                    isPremium && !missingScaffold && isChecked
                                        ? 'yes'
                                        : 'no'
                                }
                                onChange={updateWritingScaffoldOptions}
                                disabled={missingScaffold || !isPremium}
                            >
                                <FormControlLabel
                                    id={scaffold.name}
                                    name={scaffold.name}
                                    value="no"
                                    control={<Radio />}
                                    label="No"
                                    disabled={missingScaffold || !isPremium}
                                />
                                <FormControlLabel
                                    id={scaffold.name}
                                    name={scaffold.name}
                                    value="yes"
                                    control={<Radio />}
                                    label="Yes"
                                    disabled={missingScaffold || !isPremium}
                                />
                            </RadioGroup>
                        </label>
                    </Tooltip>
                );
            })}
        </div>
    );
};

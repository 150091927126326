import React from 'react';
import cn from 'classnames';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import styles from 'styles/molecules/GradeChecklist.scss';
import { GRADES } from '../../../constants/gradeLevelsV2.js';
import { Grade } from '../../../types/Room.js';

export interface GradeChecklistProps {
    selectedGrades: Grade[];
    onChange: (update: Grade[]) => void;
    className?: string;
}

export const GradeChecklist = ({
    selectedGrades,
    onChange,
    className,
}: GradeChecklistProps) => {
    return (
        <div className={cn(styles.checklist_container, className)}>
            <InputLabel htmlFor="grade-checklist-form" className={styles.dropdown_label}>
                What grade(s) do you teach? <span>(add all that apply)</span>
            </InputLabel>
            <FormControl
                id="grade-checklist-form"
                variant="filled"
                className={styles.form_container}
            >
                <Autocomplete
                    size="small"
                    multiple
                    id="grade-checklist-autocomplete"
                    options={GRADES.map((option) => option.value)}
                    disableCloseOnSelect
                    value={selectedGrades || []}
                    onChange={(e, options) => {
                        onChange(options);
                    }}
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox checked={selected} />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
            </FormControl>
        </div>
    );
};

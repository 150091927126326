import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import cn from 'classnames';
import styles from 'styles/molecules/writingScaffolds/StemAndOutlineScaffold.scss';
import { convertScaffoldKeyToPlainText } from '../../_helpers/convertScaffoldKeyToPlainText.js';
import { CopyButton } from '../../atoms/CopyButton.js';
import CircleX from '../../icons/CircleX.js';

const StemAndOutlineScaffold = ({
    scaffoldName,
    scaffoldContent,
    hideToolbarContent,
    icon,
    handleCloseScaffold,
}) => {
    const [showContent, setShowContent] = useState<boolean>(!hideToolbarContent);
    const SelectedScaffoldIcon = icon;

    useEffect(() => {
        setShowContent(!showContent);
    }, [hideToolbarContent]);

    return (
        <>
            <div className={styles.content_header}>
                <div
                    key={`${scaffoldName}-header`}
                    className={cn(
                        styles.content_header_name,
                        styles[`${scaffoldName}_color`],
                    )}
                >
                    <SelectedScaffoldIcon
                        className={cn(styles.icon, styles[`${scaffoldName}_icon`])}
                    />
                    <h4 className={styles.display_name}>
                        {convertScaffoldKeyToPlainText(scaffoldName)}
                    </h4>
                </div>
                <IconButton
                    key={`${scaffoldName}-button`}
                    onClick={() => handleCloseScaffold(scaffoldName)}
                    className={cn(styles[`${scaffoldName}_color`])}
                >
                    <CircleX strokeWidth="thin" />
                </IconButton>
            </div>
            <div className={styles.scaffold_body_container}>
                <div className={cn(styles.scaffold_body, styles[`${scaffoldName}_body`])}>
                    <Fade in={showContent} timeout={1000}>
                        <p className={styles.scaffold_text}>{scaffoldContent}</p>
                    </Fade>
                    <CopyButton
                        content={scaffoldContent}
                        className={cn(
                            styles.copy_button,
                            styles[`${scaffoldName}_color`],
                        )}
                    />
                </div>
            </div>
        </>
    );
};

export default StemAndOutlineScaffold;

import React, { Fragment, useState } from 'react';
import styles from 'styles/molecules/QuestionsTableRowsFileAttachments.scss';
import FileAttachmentThumbnail from './FileAttachmentThumbnailV2.js';
import { useQuestionFileAttachments } from '../hooks/queries/useQuestionFileAttachments.js';
import { Question } from '../../../types/Question.js';
import { PreviewFileModal } from './modals/PreviewFileModal.js';

export interface QuestionTableRowsFileAttachmentsProps {
    question: Question;
}

export const QuestionTableRowsFileAttachments = ({
    question,
}: QuestionTableRowsFileAttachmentsProps) => {
    const { attachedFiles, isLoading: isLoadingFiles } = useQuestionFileAttachments({
        question,
    });

    const [showFilePreview, setShowFilePreview] = useState<File>(null);

    const fileKeys = Object.keys(attachedFiles ?? {});

    return (
        <Fragment key={question.id}>
            {fileKeys?.length > 0 && (
                <div>
                    <h3 className={styles.h3}>Attachments</h3>
                    <div className={styles.attachments_container}>
                        {isLoadingFiles ? (
                            <div>Loading attachments...</div>
                        ) : (
                            fileKeys.map((key) => (
                                <FileAttachmentThumbnail
                                    key={key}
                                    file={attachedFiles[key]}
                                    onClick={() => setShowFilePreview(attachedFiles[key])}
                                />
                            ))
                        )}
                    </div>
                </div>
            )}
            <PreviewFileModal
                fileList={attachedFiles || {}}
                initialSelectedFile={showFilePreview}
                open={!!showFilePreview}
                onClose={() => setShowFilePreview(null)}
            />
        </Fragment>
    );
};

import React, {
    useState,
    useEffect,
    ReactNode,
    isValidElement,
    cloneElement,
} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import VerticalThreeDotsIcon from '../icons/VerticalThreeDots.js';

interface VerticalThreeDotMenuProps {
    // This is expected to be the MenuList component wrapped around list items from MUI
    menuList: ReactNode;
    // Styles for the icon button
    className?: string;
    // Anchor origin for the menu. Defaults to top left
    anchorOrigin?: {
        vertical: 'top' | 'center' | 'bottom';
        horizontal: 'left' | 'center' | 'right';
    };
    // Transform origin for the menu. Defaults to top right
    transformOrigin?: {
        vertical: 'top' | 'center' | 'bottom';
        horizontal: 'left' | 'center' | 'right';
    };
    onOpen?: () => void;
}

const VerticalThreeDotMenu = ({
    menuList,
    anchorOrigin = { vertical: 'top', horizontal: 'left' },
    transformOrigin = { vertical: 'top', horizontal: 'right' },
    className,
    onOpen,
}: VerticalThreeDotMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const hideMenu = (e?: React.MouseEvent<HTMLButtonElement>) => {
        e?.stopPropagation();
        setAnchorEl(null);
    };

    useEffect(() => {
        return () => {
            hideMenu();
        };
    }, []);

    const open = Boolean(anchorEl);

    const showMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        onOpen?.();
    };

    // Clone menu items and add onClick handler to close menu
    const wrappedMenuList = React.Children.map(menuList, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child as React.ReactElement<any>, {
                onClick: (e: React.MouseEvent) => {
                    e.stopPropagation();
                    // Call the original onClick if it exists
                    if (child.props.onClick) {
                        child.props.onClick(e);
                    }
                    // Close the menu
                    hideMenu();
                },
            });
        }
        return child;
    });

    return (
        <>
            <IconButton
                component="div"
                role="button"
                className={className}
                onClick={showMenu}
                onMouseDown={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
            >
                <VerticalThreeDotsIcon fontSize="small" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={hideMenu}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                {wrappedMenuList}
            </Menu>
        </>
    );
};

export default VerticalThreeDotMenu;

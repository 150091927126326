import React, { useState } from 'react';
import { captureException } from '@sentry/react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import styles from 'styles/molecules/modals/SendTopicQuestionsModal.scss';
import ActionPrompt from './ActionPrompt.js';
import { Topic } from '../../../../types/Topic.js';
import { useSendQuestions } from '../../hooks/mutations/useSendQuestions.js';
import { trackEvent } from '../../../lib/analytics.js';
import { AnalyticsEvents } from '../../../../types/AnalyticsEvents.js';
import ChipInput from '../../atoms/ChipInput.js';
import { Question } from '../../../../types/Question.js';
import validateEmail from '../../_helpers/validateEmail.js';

export type SendTopicQuestionsModalProps = {
    show: boolean;
    selectedTopic: Topic;
    topicQuestions: Question[];
    onClose: () => void;
};

export const SendTopicQuestionsModal = ({
    show,
    onClose,
    selectedTopic,
    topicQuestions,
}: SendTopicQuestionsModalProps) => {
    const [emails, setEmails] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<{
        message: string;
        severity: 'success' | 'error';
    } | null>(null);

    const sendQuestions = useSendQuestions();

    const handleSend = async () => {
        try {
            setLoading(true);

            await sendQuestions.mutateAsync({
                emails,
                topic: selectedTopic,
            });

            onClose();
            setLoading(false);
            setAlert({
                message:
                    'Questions shared! If the teacher has a Short Answer account, they will receive a notification.',
                severity: 'success',
            });
            trackEvent(AnalyticsEvents.topicQuestionsShared);
            setEmails([]);
        } catch (err) {
            setLoading(false);
            captureException(err);
        }
    };

    const handleUpdateEmails = (updatedEmails: string[]) => {
        setEmails(updatedEmails);
    };

    const handleClose = () => {
        setEmails([]);
        onClose();
    };

    return (
        <>
            <ActionPrompt
                open={show}
                title={`Share ${topicQuestions.length} ${selectedTopic?.name} ${
                    topicQuestions.length > 1 ? 'Questions' : 'Question'
                }`}
                subtitle={`Share ${topicQuestions.length > 1 ? 'these' : 'this'} ${
                    topicQuestions.length > 1 ? 'questions' : 'question'
                } to other teachers on Short Answer`}
                onClose={handleClose}
                content={
                    <ChipInput
                        chips={emails}
                        placeholder="Add Teacher Email"
                        className={styles.rename_topic_input}
                        onUpdate={handleUpdateEmails}
                        validate={validateEmail}
                        errorText="Please enter a valid email address"
                    />
                }
                primaryActionText="Share"
                primaryButtonLoading={loading}
                primaryButtonDisabled={emails?.length === 0}
                onPrimaryAction={handleSend}
                onSecondaryAction={handleClose}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={!!alert?.message}
                autoHideDuration={7000}
                onClose={() => setAlert(null)}
            >
                <Alert severity={alert?.severity} onClose={() => setAlert(null)}>
                    {alert?.message}
                </Alert>
            </Snackbar>
        </>
    );
};

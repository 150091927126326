import React, { useEffect, useState } from 'react';
import styles from 'styles/molecules/writingScaffolds/TranslateScaffold.scss';
import { captureException } from '@sentry/react';
import IconButton from '@mui/material/IconButton';
import { useDebouncedCallback } from 'use-debounce';
import cn from 'classnames';
import Fade from '@mui/material/Fade';
import { LanguageDropdown } from '../LanguageDropdown.js';
import { useTranslateText } from '../../hooks/mutations/useTranslateText.js';
import { CopyButton } from '../../atoms/CopyButton.js';
import { ReadAloud } from '../../atoms/ReadAloud.js';
import { convertScaffoldKeyToPlainText } from '../../_helpers/convertScaffoldKeyToPlainText.js';
import CircleX from '../../icons/CircleX.js';
import ThreeDotLoader from '../../atoms/ThreeDotLoader.js';

const TranslateScaffold = ({ icon, sourceText, handleCloseScaffold }) => {
    const [targetLanguage, setTargetLanguage] = useState<string>('es');
    const [targetText, setTargetText] = useState<string>('');
    const [isMounted, setIsMounted] = useState<boolean>(false);

    const translateText = useTranslateText();

    const translate = useDebouncedCallback(() => {
        translateText.mutate(
            {
                text: sourceText,
                sourceLanguageCode: 'auto',
                targetLanguageCode: targetLanguage,
            },
            {
                onSuccess: (data) => {
                    setTargetText(data?.text);
                },
                onError: (error) => {
                    captureException(error);
                },
            },
        );
    }, 1000);

    useEffect(() => {
        translate();

        if (!isMounted) {
            setIsMounted(true);
        }
    }, [sourceText, targetLanguage]);

    const SelectedScaffoldIcon = icon;

    return (
        <>
            <div className={styles.content_header}>
                <div
                    key="translate-header"
                    className={cn(styles.content_header_name, styles.translate_color)}
                >
                    <SelectedScaffoldIcon
                        className={cn(styles.icon, styles.translate_icon)}
                    />
                    <h4 className={styles.display_name}>
                        {convertScaffoldKeyToPlainText('translate')}
                    </h4>
                </div>
                <IconButton
                    key="translate-button"
                    onClick={() => handleCloseScaffold('translate')}
                    className={styles.translate_color}
                >
                    <CircleX strokeWidth="thin" />
                </IconButton>
            </div>
            <div className={styles.language_dropdowns}>
                <LanguageDropdown
                    languageCode={targetLanguage}
                    setLanguage={setTargetLanguage}
                    id="target-language"
                />
            </div>
            {translateText.isLoading && <ThreeDotLoader />}
            {!translateText.isLoading && !sourceText && (
                <Fade in={!sourceText} timeout={1000}>
                    <p className={styles.translate_prompt}>
                        Writing in your response will be translated here automatically.
                    </p>
                </Fade>
            )}
            {!translateText.isLoading && sourceText && (
                <div className={styles.output_container}>
                    <p className={styles.translation}>{targetText}</p>
                    <div className={styles.translate_footer}>
                        <ReadAloud content={targetText} lang={targetLanguage} />
                        <CopyButton
                            content={targetText}
                            className={styles.translate_color}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default TranslateScaffold;

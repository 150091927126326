import React from 'react';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styles from 'styles/molecules/LanguageDropdown.scss';
import { supportedLangauges } from '../../../constants/awsTranslateSupportedLanguages.js';

export interface LanguageDropdownProps {
    languageCode: string;
    setLanguage: React.Dispatch<React.SetStateAction<string>>;
    label?: string;
    id?: string;
    tabIndex?: number;
}

export const LanguageDropdown = ({
    languageCode,
    setLanguage,
    label,
    id,
    tabIndex,
}: LanguageDropdownProps) => {
    const handleSelect = (value: string) => {
        setLanguage(value);
    };

    const languages = { ...supportedLangauges, '': '' };

    return (
        <div className={styles.container}>
            <FormControl variant="standard" className={styles.form_control}>
                <label htmlFor={id} className={styles.label}>
                    {label}
                </label>
                <Autocomplete
                    id={id}
                    tabIndex={tabIndex}
                    className={styles.menu}
                    options={Object.keys(languages)}
                    value={languageCode}
                    onChange={(e, value) => handleSelect(value)}
                    getOptionLabel={(option) => languages[option]}
                    renderOption={(props, option) => {
                        return <li {...props}>{languages[option]}</li>;
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                id="select-language-input"
                                placeholder="Select Language..."
                                InputProps={{
                                    ...params.InputProps,
                                    className: styles.input,
                                }}
                            />
                        );
                    }}
                />
            </FormControl>
        </div>
    );
};

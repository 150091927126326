import React from 'react';
import cn from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import styles from 'styles/molecules/LoaderMessage.scss';

export interface LoaderMessageProps {
    /** Class name to apply to wrapping div component */
    className?: string;
    /** Message to be shown inside Loader (300 px wide) */
    message?: string | JSX.Element;
    /** determinate stops the loader from spinning, indeterminate by default */
    variant?: 'determinate' | 'indeterminate';
    /** Color of the spinner, 'success' by default */
    loaderColor?: 'yellow' | 'white';
}

const LoaderMessage = (props: LoaderMessageProps) => {
    const {
        message,
        variant = 'indeterminate',
        loaderColor = 'yellow',
        className,
    } = props;
    return (
        <div className={cn(styles.container, className)}>
            <CircularProgress
                size="inherit"
                thickness={2.5}
                className={cn({
                    [styles.yellow]: loaderColor === 'yellow',
                    [styles.white]: loaderColor === 'white',
                })}
                variant={variant}
                value={100}
            />

            <Typography className={cn([styles.text, styles.white])}>{message}</Typography>
        </div>
    );
};

export default LoaderMessage;

import React from 'react';
import Typography from '@mui/material/Typography';
import styles from 'styles/molecules/GoogleButton.scss';
import Button from '../atoms/Button.js';
import GoogleLogo from '../icons/svgs/GoogleLogo.svg';

export interface GoogleButtonProps {
    onClick: () => void;
    showTosMessage?: boolean;
}

export const GoogleButton = ({ onClick, showTosMessage }: GoogleButtonProps) => {
    return (
        <div className={styles.container}>
            <Button
                className={styles.button}
                startIcon={<GoogleLogo />}
                onClick={onClick}
            >
                Continue with Google
            </Button>
            {showTosMessage && (
                <Typography className={styles.tos_text}>
                    By clicking Continue with Google you agree to the{' '}
                    <a
                        href="https://myshortAnswer.com/terms-of-service/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        terms of service{' '}
                    </a>{' '}
                    and{' '}
                    <a
                        href="https://myshortanswer.com/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        privacy policy
                    </a>{' '}
                    and confirm that you are a teacher over 18 years of age.
                </Typography>
            )}
        </div>
    );
};

import React, { MouseEvent } from 'react';
import cn from 'classnames';
import { SvgIconProps } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import styles from 'styles/molecules/modals/ActionPrompt.scss';
import Button from '../../atoms/Button.js';
import { Dialog } from '../../atoms/Dialog.js';

export interface ActionPromptProps {
    /** Whether to show the modal */
    open: boolean;
    /** Callback function to call when closing dialog */
    onClose: () => void;
    /** Text to show in DialogTitle */
    title?: string;
    /** Text to show in DialogTitle */
    subtitle?: string;
    /** Icon to be placed to left of title */
    headerIcon?: (props: SvgIconProps) => React.JSX.Element;
    /** Styles to pass to headerIcon */
    headerIconClassName?: string;
    /** Content to be slotted under the title */
    content?: React.ReactNode;
    /** Text to show on top confirm button, defaults to "Confirm" */
    primaryActionText?: string;
    /** Text to show on bottom cancel button, defaults to "Cancel" */
    secondaryActionText?: string;
    /** Callback function to call when clicking the primary action button */
    onPrimaryAction: (e: MouseEvent<HTMLButtonElement>) => void;
    /** Callback function to call when clicking the secondary action button */
    onSecondaryAction: (e: MouseEvent<HTMLButtonElement>) => void;
    /** Whether the primary button is loading */
    primaryButtonLoading?: boolean;
    /** Whether the primary button is disabled */
    primaryButtonDisabled?: boolean;
}

const ActionPrompt = (props: ActionPromptProps) => {
    const {
        open,
        onClose,
        title,
        subtitle,
        headerIcon,
        headerIconClassName,
        content,
        primaryActionText = 'Confirm',
        secondaryActionText = 'Cancel',
        onPrimaryAction,
        onSecondaryAction = onClose,
        primaryButtonLoading,
        primaryButtonDisabled,
    } = props;

    const HeaderIcon = headerIcon;

    return (
        <Dialog open={open} onClose={onClose}>
            <span className={styles.container}>
                {title && (
                    <div
                        className={cn({
                            [styles.header_container]: !!headerIcon,
                        })}
                    >
                        {HeaderIcon && <HeaderIcon className={headerIconClassName} />}
                        <DialogTitle className={styles.dialog_title}>{title}</DialogTitle>
                    </div>
                )}
                {subtitle && (
                    <DialogTitle component="h3" className={styles.subtitle}>
                        {subtitle}
                    </DialogTitle>
                )}
                {content && (
                    <DialogContent className={styles.dialog_content}>
                        {content}
                    </DialogContent>
                )}
                <DialogActions>
                    <Button
                        className={styles.button}
                        kind="text"
                        onClick={onSecondaryAction}
                    >
                        {secondaryActionText}
                    </Button>
                    <Button
                        className={styles.button}
                        kind="primary"
                        onClick={onPrimaryAction}
                        loading={primaryButtonLoading}
                        disabled={primaryButtonDisabled}
                    >
                        {primaryActionText}
                    </Button>
                </DialogActions>
            </span>
        </Dialog>
    );
};

export default ActionPrompt;

import React from 'react';
import cn from 'classnames';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import styles from 'styles/molecules/StateDropdown.scss';
import countries from '../../../constants/countriesList.js';

export interface CountryDropdownProps {
    teachingCountry: string;
    setTeachingCountry: React.Dispatch<React.SetStateAction<string>>;
    className?: string;
}

export const CountryDropdown = ({
    teachingCountry,
    setTeachingCountry,
    className,
}: CountryDropdownProps) => {
    const countryNames = countries.map((country) => country.name);

    return (
        <div className={cn(styles.container, className)}>
            <InputLabel htmlFor="select-state" className={styles.dropdown_label}>
                What country do you teach in?
            </InputLabel>
            <FormControl variant="outlined" className={styles.form_control}>
                <Autocomplete
                    size="small"
                    className={styles.autocomplete}
                    options={countryNames}
                    value={teachingCountry || ''}
                    onChange={(_, value: string) => setTeachingCountry(value)}
                    getOptionLabel={(option: string) => option}
                    isOptionEqualToValue={(option: string, value: string) => {
                        // MUI throws a warning if the initial value is a ''
                        if (value === '') return true;
                        return option === value;
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
            </FormControl>
        </div>
    );
};

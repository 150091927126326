import React, { useContext, useRef, useState } from 'react';
import cn from 'classnames';
import Card from '@mui/material/Card';
import styles from 'styles/molecules/AddResponseToolbar.scss';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import LightBulbOutline from '../icons/LightBulbOutline.js';
import List from '../icons/List.js';
import Checkmark from '../icons/Checkmark.js';
import { WritingScaffolds } from '../../../types/WritingScaffolds.js';
import Language from '../icons/Language.js';
import { SessionContext } from '../context/Session.js';
import { convertScaffoldKeyToPlainText } from '../_helpers/convertScaffoldKeyToPlainText.js';
import StemAndOutlineScaffold from './writingScaffolds/StemAndOutlineScaffold.js';
import TranslateScaffold from './writingScaffolds/TranslateScaffold.js';
import VerticalThreeDotMenu from './VerticalThreeDotMenu.js';

export interface AddResponseToolbarProps {
    // List of scaffolds to display. These are the scaffolds persisted to DB that the teacher has chosen to use
    enabledScaffolds: Array<keyof WritingScaffolds>;
    // Handles on click behavior designated by the parent
    onClickScaffold: (scaffold: keyof WritingScaffolds) => void;
    // This is the response string typed into the TextAreaInput on AddResponse page
    response: string;
    // Determines if the text field is focused or not
    isTextFieldFocused?: boolean;
    // Sets the text field focus value
    setIsTextFieldFocused: React.Dispatch<React.SetStateAction<boolean>>;
}

const scaffoldIcons = {
    'sentence-stems': LightBulbOutline,
    outline: List,
    translate: Language,
    // cue: Robot
};

const AddResponseToolbar = ({
    enabledScaffolds,
    response,
    onClickScaffold,
    isTextFieldFocused,
    setIsTextFieldFocused,
}: AddResponseToolbarProps) => {
    const session = useContext(SessionContext);

    const [hideToolbarContent, setHideToolbarContent] = useState<boolean>(true);
    const [selectedScaffold, setSelectedScaffold] = useState<keyof WritingScaffolds>();

    const toolbarRef = useRef<HTMLDivElement>(null);

    const handleClickScaffold = (scaffold: keyof WritingScaffolds) => {
        // set the currently selected scaffold
        setSelectedScaffold(scaffold);

        // handle parent behavior (grid changes)
        onClickScaffold?.(scaffold);

        // show the content
        setHideToolbarContent(false);
    };

    const handleCloseScaffold = (scaffold: keyof WritingScaffolds) => {
        // reset the selected scaffold
        onClickScaffold?.(scaffold);

        // hide the toolbar content
        setHideToolbarContent(true);
    };

    const SelectedScaffoldIcon = scaffoldIcons[selectedScaffold] ?? null;

    const scaffoldContent =
        session?.question_writing_scaffolds?.[selectedScaffold]?.trim();

    return (
        <>
            {hideToolbarContent && (
                <>
                    <div
                        className={cn([
                            styles.show_header_xs,
                            {
                                [styles.show_header_btn]: isTextFieldFocused,
                                [styles.hide_header_btn]: !isTextFieldFocused,
                            },
                        ])}
                    >
                        <Button
                            id="done-btn-xs"
                            key="done-btn-xs"
                            onMouseDown={(e) => {
                                e.preventDefault();
                                e.currentTarget.focus();
                                setIsTextFieldFocused(false);
                            }}
                            startIcon={<Checkmark className={styles.checkmark_icon} />}
                            className={styles.done_btn_xs}
                        >
                            Done
                        </Button>
                        {enabledScaffolds?.length > 0 && (
                            <VerticalThreeDotMenu
                                className={styles.threedot_xs}
                                menuList={enabledScaffolds?.map((scaffold) => {
                                    const ScaffoldIcon = scaffoldIcons[scaffold];
                                    const scaffoldName =
                                        convertScaffoldKeyToPlainText(scaffold);

                                    return (
                                        <MenuItem
                                            key={scaffold}
                                            className={styles.toolbar_button}
                                            id={scaffold}
                                        >
                                            <Button
                                                onClick={() =>
                                                    handleClickScaffold(scaffold)
                                                }
                                                className={cn(
                                                    styles.toolbar_button,
                                                    styles[`${scaffold}_hover`],
                                                )}
                                                startIcon={
                                                    <ScaffoldIcon
                                                        className={cn(
                                                            styles.toolbar_icon,
                                                            styles[
                                                                `${scaffold}_toolbar_icon`
                                                            ],
                                                        )}
                                                    />
                                                }
                                            >
                                                {scaffoldName}
                                            </Button>
                                        </MenuItem>
                                    );
                                })}
                            />
                        )}
                    </div>

                    <div ref={toolbarRef} className={styles.container}>
                        <Slide
                            in={hideToolbarContent}
                            timeout={350}
                            direction="right"
                            container={toolbarRef.current}
                        >
                            <Card className={styles.tools_card}>
                                <Fade in={hideToolbarContent} timeout={1000}>
                                    <h2 className={styles.h2}>Tools</h2>
                                </Fade>
                                {enabledScaffolds?.map((scaffold) => {
                                    const ScaffoldIcon = scaffoldIcons[scaffold];
                                    const scaffoldName =
                                        convertScaffoldKeyToPlainText(scaffold);

                                    return (
                                        <Fade
                                            key={scaffold}
                                            in={hideToolbarContent}
                                            timeout={1000}
                                        >
                                            <div
                                                className={styles.toolbar_button}
                                                id={scaffold}
                                            >
                                                <Button
                                                    onClick={() =>
                                                        handleClickScaffold(scaffold)
                                                    }
                                                    className={cn(
                                                        styles.toolbar_button,
                                                        styles[`${scaffold}_hover`],
                                                    )}
                                                    startIcon={
                                                        <ScaffoldIcon
                                                            className={cn(
                                                                styles.toolbar_icon,
                                                                styles[
                                                                    `${scaffold}_toolbar_icon`
                                                                ],
                                                            )}
                                                        />
                                                    }
                                                >
                                                    {scaffoldName}
                                                </Button>
                                            </div>
                                        </Fade>
                                    );
                                })}
                            </Card>
                        </Slide>
                    </div>
                </>
            )}
            {!hideToolbarContent && (
                <div ref={toolbarRef} className={styles.container}>
                    <Slide in={!hideToolbarContent} timeout={350} direction="left">
                        <Card className={styles.content_card}>
                            {(selectedScaffold === 'sentence-stems' ||
                                selectedScaffold === 'outline') && (
                                <StemAndOutlineScaffold
                                    icon={SelectedScaffoldIcon}
                                    scaffoldName={selectedScaffold}
                                    handleCloseScaffold={handleCloseScaffold}
                                    scaffoldContent={scaffoldContent}
                                    hideToolbarContent
                                />
                            )}
                            {selectedScaffold === 'translate' && (
                                <TranslateScaffold
                                    icon={SelectedScaffoldIcon}
                                    handleCloseScaffold={handleCloseScaffold}
                                    sourceText={response}
                                />
                            )}
                            {selectedScaffold === 'cue' && (
                                // Cue component goes here
                                <h2>Cue content goes here!</h2>
                            )}
                        </Card>
                    </Slide>
                </div>
            )}
        </>
    );
};

export default AddResponseToolbar;

import React, { Fragment, useState, useContext } from 'react';
import styles from 'styles/molecules/QuestionsTableRow.scss';
import cn from 'classnames';
import { useQueryClient } from 'react-query';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Collapse from '@mui/material/Collapse';
import { NavLink, useNavigate } from 'react-router-dom';
import { trackEvent } from '../../lib/analytics.js';
import { AnalyticsEvents } from '../../../types/AnalyticsEvents.js';
import FeedbackChip from './FeedbackChipV2.js';
import RocketShip from '../icons/RocketShip.js';
import {
    formatDateDistance,
    isDateRecentlyAdded,
} from '../_helpers/formatDateDistance.js';
import { RoomContext } from '../context/Room.js';
import VerticalThreeDotMenu from './VerticalThreeDotMenu.js';
import Pencil from '../icons/Pencil.js';
import Duplicate from '../icons/Duplicate.js';
import Trash from '../icons/Trash.js';
import { SelectActivityModal } from '../organisms/SelectActivityModal/index.js';
import { MaxCriteriaExceededModal } from './modals/MaxCriteriaExceededModal.js';
import ActionPrompt from './modals/ActionPrompt.js';
import Warning from '../icons/Warning.js';
import { useDeleteQuestion } from '../hooks/mutations/useDeleteQuestion.js';
import { QueryKeys } from '../../../constants/queryKeys.js';
import { hasPremiumSubscription } from '../../../shared/subscriptionStatusHelpers.js';
import { Question } from '../../../types/Question.js';
import { Topic } from '../../../types/Topic.js';
import { QuestionTableRowsFileAttachments } from './QuestionTableRowsFileAttachments.js';

export interface QuestionsTableRowProps {
    question: Question;
    topic: Topic;
}

export const QuestionsTableRow = ({ question, topic }: QuestionsTableRowProps) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const room = useContext(RoomContext);

    const isPremium = hasPremiumSubscription(room);

    const [expanded, setExpanded] = useState(false);
    const [showSelectActivityModal, setShowSelectActivityModal] = useState(false);
    const [showMaxCriteriaExceededModal, setShowMaxCriteriaExceededModal] =
        useState(false);
    const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);

    const deleteQuestion = useDeleteQuestion();

    const handleDelete = () => {
        trackEvent(AnalyticsEvents.questionDeleted);

        deleteQuestion.mutate(
            { questionId: question.id, roomId: room.id },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(QueryKeys.allRoomQuestions);
                    setShowDeleteQuestionModal(false);
                },
            },
        );
    };

    const handleLaunch = async () => {
        const isFeedBackCriteriaExceeded = question?.feedback_opts?.values?.length > 3;

        if (isFeedBackCriteriaExceeded && !isPremium) {
            setShowMaxCriteriaExceededModal(true);
        } else {
            setShowSelectActivityModal(true);
        }

        await trackEvent(AnalyticsEvents.launchButtonClicked, {
            location: 'question_card',
            question: question.question,
            question_id: question.id,
            feedback_criteria: question.feedback_opts,
            exceededCriteria: isFeedBackCriteriaExceeded && !isPremium,
            isPremium,
        });
    };

    const handleClickRow = () => {
        setExpanded(!expanded);
    };

    const redirectToEditQuestionPage = () => {
        navigate(`/questions/wizard/${question.id}`);
    };

    return (
        <Fragment key={question.id}>
            <TableRow
                onClick={handleClickRow}
                key={question.id}
                className={cn(
                    isDateRecentlyAdded(question.updated_at) ? 'new-row' : undefined,
                    styles.row,
                )}
            >
                <TableCell
                    className={cn([
                        styles.column,
                        {
                            [styles.question_column_collapsed]: !expanded,
                            [styles.question_column_expanded]: expanded,
                        },
                    ])}
                >
                    {question.question}
                    <Collapse in={expanded}>
                        <div className={styles.expanded_content_container}>
                            {expanded && (
                                <QuestionTableRowsFileAttachments question={question} />
                            )}
                            <div>
                                <h3 className={styles.h3}>Feedback Criteria</h3>
                                <div className={styles.feedback_criteria_container}>
                                    {question.feedback_opts?.values?.map((feedback) => (
                                        <FeedbackChip
                                            key={feedback.id}
                                            feedbackValue={feedback}
                                            className={styles.feedback_chip}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </TableCell>
                <TableCell
                    className={cn([
                        styles.column,
                        {
                            [styles.topic_column_collapsed]: !expanded,
                            [styles.topic_column_expanded]: expanded,
                        },
                    ])}
                >
                    {!topic ? 'N/A' : topic?.name}
                </TableCell>
                <TableCell className={cn([styles.column, styles.updated_column])}>
                    {formatDateDistance(question.updated_at)}
                </TableCell>
                <TableCell
                    align="center"
                    className={cn([styles.column, styles.launch_column])}
                >
                    <IconButton className={styles.launch_button} onClick={handleLaunch}>
                        <RocketShip className={styles.launch_icon} />
                    </IconButton>
                </TableCell>
                <TableCell align="left" className={styles.column}>
                    <VerticalThreeDotMenu
                        className={styles.vertical_three_dot}
                        menuList={
                            <MenuList className={styles.menu_list}>
                                <MenuItem
                                    component={NavLink}
                                    to={`/questions/wizard/${question.id}`}
                                    className={styles.menu_item}
                                    onClick={() =>
                                        trackEvent(
                                            AnalyticsEvents.editQuestionButtonClicked,
                                        )
                                    }
                                >
                                    <ListItemIcon>
                                        <Pencil
                                            fontSize="small"
                                            className={styles.menu_item_icon}
                                        />
                                    </ListItemIcon>
                                    <ListItemText className={styles.menu_item_text}>
                                        Edit
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem
                                    component={NavLink}
                                    to={`/questions/wizard/${question.id}`}
                                    state={{
                                        isDuplicate: true,
                                    }}
                                    onClick={() =>
                                        trackEvent(AnalyticsEvents.questionDuplicated)
                                    }
                                >
                                    <ListItemIcon>
                                        <Duplicate
                                            fontSize="small"
                                            className={styles.menu_item_icon}
                                        />
                                    </ListItemIcon>
                                    <ListItemText className={styles.menu_item_text}>
                                        Duplicate
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => setShowDeleteQuestionModal(true)}
                                >
                                    <ListItemIcon>
                                        <Trash
                                            fontSize="small"
                                            className={styles.menu_item_icon}
                                        />
                                    </ListItemIcon>
                                    <ListItemText className={styles.menu_item_text}>
                                        Delete
                                    </ListItemText>
                                </MenuItem>
                            </MenuList>
                        }
                    />
                </TableCell>
            </TableRow>

            <SelectActivityModal
                show={showSelectActivityModal}
                onClose={() => setShowSelectActivityModal(false)}
                question={question}
            />
            <MaxCriteriaExceededModal
                show={showMaxCriteriaExceededModal}
                onClose={() => setShowMaxCriteriaExceededModal(false)}
                onEdit={() => redirectToEditQuestionPage()}
            />
            <ActionPrompt
                open={showDeleteQuestionModal}
                onClose={() => setShowDeleteQuestionModal(false)}
                title="Sure you want to delete?"
                headerIcon={Warning}
                headerIconClassName={styles.warning_icon}
                content={
                    <div className={styles.delete_question_modal_content}>
                        <strong>By deleting this question:</strong>
                        <ul>
                            <li>
                                Student responses associated with this question will be
                                lost and <strong>permanently deleted</strong>
                            </li>
                        </ul>
                    </div>
                }
                primaryActionText="Delete"
                onPrimaryAction={handleDelete}
                secondaryActionText="Cancel"
                onSecondaryAction={() => setShowDeleteQuestionModal(false)}
            />
        </Fragment>
    );
};

import { WritingScaffolds } from '../../../types/WritingScaffolds.js';
import LightBulbOutline from '../icons/LightBulbOutline.js';
import List from '../icons/List.js';
import Language from '../icons/Language.js';

/**
 * These are the options for writing scaffolds that can be enabled for a question
 */
type ScaffoldOption = {
    name: keyof WritingScaffolds;
    description: string;
    icon: React.ElementType;
    disabledTooltipMessage: string;
};

const scaffoldOptions: Array<ScaffoldOption> = [
    {
        name: 'sentence-stems',
        description:
            'Give students sentence stems to help them get started with their writing.',
        icon: LightBulbOutline,
        disabledTooltipMessage:
            'To enable this scaffold, edit the question and add sentence stems.',
    },
    {
        name: 'outline',
        description: 'Provide students an outline to help them structure their writing.',
        icon: List,
        disabledTooltipMessage:
            'To enable this scaffold, edit the question and add an outline.',
    },
    {
        name: 'translate',
        description:
            'Translate writing to support students more comfortable with another language.',
        icon: Language,
        disabledTooltipMessage: '',
    },
];

export default scaffoldOptions;

import { AvatarProps } from '../organisms/Avatar/index.js';

export const avatars: AvatarProps[] = [
    {
        id: '100',
        avatarSpecs: {
            bgColor: 'transparent',
            shapeColor: 'purple',
            shape: 'square',
            face: 'face-37',
        },
        width: 140,
        showBase: false,
    },
    {
        id: '200',
        avatarSpecs: {
            bgColor: 'transparent',
            shapeColor: 'blue',
            shape: 'circle',
            face: 'face-12',
        },
        width: 110,
        showBase: false,
    },
    {
        id: '300',
        avatarSpecs: {
            bgColor: 'transparent',
            shapeColor: 'orange',
            shape: 'rounded',
            face: 'face-37',
        },
        width: 120,
        showBase: false,
    },
    {
        id: '400',
        avatarSpecs: {
            bgColor: 'transparent',
            shapeColor: 'green',
            shape: 'circle',
            face: 'face-32',
        },
        width: 94,
        showBase: false,
    },
    {
        id: '500',
        avatarSpecs: {
            bgColor: 'transparent',
            shapeColor: 'pink',
            shape: 'diamond',
            face: 'face-21',
        },
        width: 110,
        showBase: false,
    },
    {
        id: '600',
        avatarSpecs: {
            bgColor: 'transparent',
            shapeColor: 'red',
            shape: 'square',
            face: 'face-1',
        },
        width: 170,
        showBase: false,
    },
];

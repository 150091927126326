import React from 'react';
import cn from 'classnames';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styles from 'styles/molecules/StateDropdown.scss';
import { statesList } from '../../../constants/statesList.js';

export interface StateDropdownProps {
    teachingState: string;
    setTeachingState: React.Dispatch<React.SetStateAction<string>>;
    className?: string;
}

export const StateDropdown = ({
    teachingState,
    setTeachingState,
    className,
}: StateDropdownProps) => {
    return (
        <div className={cn(styles.container, className)}>
            <InputLabel htmlFor="select-state" className={styles.dropdown_label}>
                What state do you teach in?
            </InputLabel>
            <FormControl variant="outlined" className={styles.form_control}>
                <Autocomplete
                    size="small"
                    className={styles.autocomplete}
                    options={statesList}
                    value={teachingState || ''}
                    onChange={(_, value: string) => setTeachingState(value)}
                    getOptionLabel={(option: string) => option}
                    isOptionEqualToValue={(option: string, value: string) => {
                        // MUI throws a warning if the initial value is a ''
                        if (value === '') return true;
                        return option === value;
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
            </FormControl>
        </div>
    );
};

import cn from 'classnames';
import React from 'react';
import styles from 'styles/molecules/FeedbackChipV2.scss';
import { FeedbackValue } from '../../../types/Feedback.js';
import CircleCheckmarkOutline from '../icons/CircleCheckmarkOutline.js';

interface FeedbackChipV2Props {
    feedbackValue: FeedbackValue;
    checkmarkColor?: string;
    className?: string;
    // This dictates the stroke width of the checkmark
    strokeWidth?: 'thin' | 'thick';
}

export const FeedbackChip = ({
    feedbackValue,
    checkmarkColor,
    className,
    strokeWidth,
}: FeedbackChipV2Props) => {
    const variableColorStyle = {
        '--var-color': checkmarkColor,
    } as React.CSSProperties;

    return (
        <span
            key={feedbackValue?.id}
            style={variableColorStyle}
            className={cn([styles.container, styles.variable_color, className])}
        >
            <CircleCheckmarkOutline className={styles.icon} strokeWidth={strokeWidth} />
            <p className={styles.p}>{feedbackValue?.feedback}</p>
        </span>
    );
};

export default FeedbackChip;
